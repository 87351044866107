<script setup lang="ts">
  import { MpInfiniteScroll, MpMasonryWall } from '@borg/ui';
  import { isNumber } from '@borg/utils';

  await getFeaturedJobs();

  const { t } = useI18n();
  const data = useFeaturedJobs();
  const page = useFeaturedJobsPage();
  const pending = ref(false);
  const ssrHydration = computed(() => process.server || useNuxtApp().isHydrating);
  const isAllLoaded = computed(
    () => isNumber(data.value?.totalPages) && page.value >= data.value.totalPages,
  );
  const showMoreButtonText = computed(() =>
    isAllLoaded.value ? undefined : t('INDEX_PAGE.FEATURED_JOBS_LOAD_MORE'),
  );

  async function loadMore() {
    if (pending.value || isAllLoaded.value) {
      return;
    }
    pending.value = true;
    page.value += 1;
    const nextData = await jobsService.getFeaturedJobs({ page: page.value });
    if (data.value) {
      data.value = {
        ...data.value,
        items: [...data.value.items, ...nextData.items],
        total: nextData.total,
        totalPages: nextData.totalPages,
      };
    }
    pending.value = false;
  }
</script>

<template>
  <HomepageJobsSection
    v-if="!!data?.items?.length"
    :title="$t('INDEX_PAGE.FEATURED_JOBS_SECTION_TITLE')"
  >
    <MpInfiniteScroll
      :distance="800"
      :is-loading="pending"
      :show-more-button-text="showMoreButtonText"
      @near-end="loadMore"
    >
      <MpMasonryWall
        :items="data.items"
        name="index"
        :width="300"
        :gap="16"
        :columns="4"
        :ssr-hydration="ssrHydration"
      >
        <template #default="{ item }">
          <JobCardFeatured
            :job-group="item"
            source="homepage_featured"
          />
        </template>
      </MpMasonryWall>
    </MpInfiniteScroll>
  </HomepageJobsSection>
</template>
