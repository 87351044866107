<script setup lang="ts">
  import { register } from 'swiper/element/bundle';
  import { MpCard } from '@borg/ui';
  import { JobGroup, Unpacked } from '@borg/types';

  const props = defineProps<{
    jobGroup: JobGroup;
  }>();

  defineSlots<{
    postLogo: (props: { job: Unpacked<JobGroup['jobs']> }) => void;
  }>();

  register();

  const jobCard = useJobCard.provide();
  jobCard.set(props.jobGroup);
  const organizationCard = useOrganizationCard.provide();
  organizationCard.set(props.jobGroup.organization);

  const isSwiperEnabled = computed(() => props.jobGroup.jobs.length > 1);
</script>

<template>
  <MpCard
    class="job-card-container"
    p="lg"
    shadow
    border="light-only"
  >
    <div class="job-card">
      <div
        :class="{
          'job-card__logo-container': true,
          'job-card__post-logo-container': !!$slots['postLogo'],
        }"
      >
        <OrganizationLogo
          large
          centered
          class="job-card__logo"
        />
        <slot
          name="postLogo"
          :job="jobGroup.jobs[0]"
        />
      </div>
      <swiper-container
        v-if="isSwiperEnabled"
        class="job-card__swiper-container"
        autoplay-delay="3000"
        autoplay-pause-on-mouse-enter="true"
        autoplay-disable-on-interaction="false"
        pagination-type="bullets"
        pagination-clickable="true"
        loop="true"
      >
        <swiper-slide
          v-for="(job, i) in jobGroup.jobs"
          :key="job.id"
          :class="{
            'job-card__swiper-slide': true,
            'swiper-slide-active': i === 0,
            'swiper-slide-next': i === 1,
          }"
        >
          <div class="job-card__job-content job-card__job-content--swipeable">
            <JobCardContent
              :job="job"
              :organization="jobGroup.organization"
              job-title-as="h4"
              source="homepage_exclusive"
            />
            <slot
              name="postLogo"
              :job="job"
            />
          </div>
        </swiper-slide>
      </swiper-container>
      <template v-else>
        <div class="job-card__non-swiper-container">
          <div class="job-card__job-content">
            <JobCardContent
              :job="jobGroup.jobs[0]"
              :organization="jobGroup.organization"
              job-title-as="h4"
              source="homepage_exclusive"
            />
          </div>
        </div>
      </template>
    </div>
    <JobCardBadges exclusive />
  </MpCard>
</template>

<style scoped lang="scss">
  .job-card-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .job-card {
    --swiper-pagination-color: var(--mp-color-secondary-default);
    --swiper-pagination-bullet-inactive-color: var(--mp-color-secondary-light);
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bottom: 0;

    $logoMaxWidth: 160px;
    $logoMinHeight: 100px;
    $gap: var(--mp-space-70);

    display: flex;
    flex-direction: column;
    gap: $gap;

    @include screen-md {
      flex-direction: column;
    }

    &__logo-container {
      display: flex;
      justify-content: center;
    }

    &__post-logo-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .job-card__logo {
        margin-left: calc(var(--mp-space-60) * -1);
      }
    }

    &__logo {
      display: inline-block;
      width: $logoMaxWidth;

      @include screen-xl {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $logoMinHeight;
      }
    }

    &__swiper-container,
    &__non-swiper-container {
      display: inline-block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    &__swiper-slide {
      display: inline-block;
      height: auto;
      width: 100%;
    }

    &__job-content {
      display: flex;
      flex-direction: column;
      padding-left: var(--mp-space-10);
      flex-grow: 2;

      &--swipeable {
        padding-bottom: var(--mp-space-60);
      }
    }
  }
</style>
