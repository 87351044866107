<template>
  <div class="homepage">
    <ExclusiveJobs />
    <VideoJobs />
    <FeaturedJobs />
  </div>
</template>

<style scoped lang="scss">
  .homepage {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-100);
  }
</style>
